

<form [formGroup]="companyFormGroup" class="company-registration flex flex-wrap" >

  <div class="company-registration__row u__form-row">   
    <mat-form-field class= "u__form--300">
      <mat-label i18n>Země</mat-label>
      <mat-select formControlName="country">
        <mat-option value="CZ" i18n>Česko</mat-option>
        <mat-option value="SK" i18n>Slovensko</mat-option>
        <mat-option value="PL" i18n>Polsko</mat-option>
      </mat-select>
      <mat-error *ngIf="f['country'].hasError('require')" i18n>Povinný údaj</mat-error>
    </mat-form-field>
    <mat-form-field >
      <mat-label i18n>IČO</mat-label>
      <input matInput i18n-placeholder placeholder="IČO" formControlName="regnum" >
      <button *ngIf="company!.country == 'CZ' && isNew" mat-icon-button matSuffix (click)="findCompanyByRegnum()" aria-label="Najít společnost podle ičo">
        <mat-icon>search</mat-icon>
      </button>
      <mat-error *ngIf="f['regnum'].hasError('require')" i18n>Povinný údaj</mat-error>
      <mat-error *ngIf="f['regnum'].hasError('incorrect')" i18n>Nepodařilo se ověřit vaše ičo</mat-error>
    </mat-form-field>
    <mat-form-field >
      <mat-label i18n>DIČ</mat-label>
      <input matInput i18n-placeholder placeholder="DIČ" formControlName="vatnum" >
    </mat-form-field>
  </div>
  <div class="u__form-row company-registration__row">
    <mat-form-field class= "u__flex--halfrow">
      <mat-label i18n>Ulice a č.p.</mat-label>
      <input matInput i18n-placeholder placeholder="Ulice a č.p."  formControlName="street" >
      <mat-error *ngIf="f['street'].hasError('require')" i18n>Povinný údaj</mat-error>
    </mat-form-field>
    <mat-form-field class= "u__flex--halfrow">
      <mat-label i18n>Město</mat-label>
      <input matInput i18n-placeholder placeholder="Město"  formControlName="city" >
      <mat-error *ngIf="f['city'].hasError('require')" i18n>Povinný údaj</mat-error>
    </mat-form-field>
    <mat-form-field class= "u__form--100">
      <mat-label i18n>PSČ</mat-label>
      <input  matInput i18n-placeholder placeholder="PSČ"  formControlName="zip" >
      <mat-error *ngIf="f['zip'].hasError('require')" i18n>Povinný údaj</mat-error>
    </mat-form-field>
   
  </div>
  <div class="company-registration__row u__form-row">
   
    <mat-form-field class="u__flex--halfrow">
      <mat-label i18n>Kontaktní email</mat-label>
      <input matInput i18n-placeholder placeholder="Kontaktní email" formControlName="emailPrimary" >
      <mat-error type="email" *ngIf="f['emailPrimary'].hasError('require')" i18n>Povinný údaj</mat-error>
      <mat-error *ngIf="f['emailPrimary'].hasError('email')" i18n>Email není ve správném formátu</mat-error>

    </mat-form-field>

    <mat-form-field class="u__flex--halfrow">
      <mat-label i18n>Fakturační email</mat-label>
      <input type="email" matInput i18n-placeholder placeholder="Fakturační email" formControlName="emailInvoice" >
      <mat-error *ngIf="f['emailInvoice'].hasError('require')" i18n>Povinný údaj</mat-error>
      <mat-error *ngIf="f['emailInvoice'].hasError('email')" i18n>Email není ve správném formátu</mat-error>

    </mat-form-field>
  </div>
</form>