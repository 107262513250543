import { getCurrencySymbol } from '@angular/common';
import { Component, Inject, LOCALE_ID,ViewEncapsulation} from '@angular/core';
import { MAT_DIALOG_DATA,  MatDialogRef } from '@angular/material/dialog';
import { PlanItem, PlanType } from 'src/app/models/plan.model';
import { OrderService } from 'src/app/orders/order.service';
import { CustomUtils } from 'src/app/utils/custom-utils.component';

@Component({
  selector: 'app-plan-detail-dialog',
  templateUrl: './plan-detail-dialog.component.html',
  styleUrls: ['./plan-detail-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PlanDetailDialogComponent{
  plan?: PlanItem;
  planTypeEnum: typeof PlanType = PlanType;
  companyCurrency: string  = "CZK";
  constructor( 
    private orderService: OrderService,
    public dialogRef: MatDialogRef<PlanDetailDialogComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(LOCALE_ID) public locale: string,
  ){ 
    if(data) {
      this.plan = data.plan;

    }
  }


  ngOnInit() {}
  openOrderStepper() {
    
    if(this.plan) {
      this.orderService.openOrderDialog(this.plan);
      this.dialogRef.close();
    }
  }
  calculateVatPrice(prices: any):any {
    if (this.companyCurrency && this.plan) {
      let vatPrices = Object.assign({}, prices);
      vatPrices[this.companyCurrency] = (vatPrices[this.companyCurrency] * CustomUtils.getPercentMultiplayer(this.plan.vatRate)).toFixed(2);
      return vatPrices;
    }
    return prices;
  }

  calculateVatPriceNumber(number: any):any {
    if(this.plan)
    return (number * CustomUtils.getPercentMultiplayer(this.plan.vatRate)).toFixed(2) + " "+ getCurrencySymbol(this.companyCurrency, "narrow");
  }
  isFeatureAllowed(feature: any ) {
    if(typeof feature.allowed == 'undefined') {
      return true;
    }
    return feature.allowed;
  }
}
